const LinkList = [
  {
    id: 1,
    title: 'Legal',
    diffClass: 'md:col-start-7',
    list: [
      {
        id: 1,
        href: '/terms',
        text: 'Términos y condiciones',
      },
      {
        id: 2,
        href: '/privacy',
        text: 'Políticas de privacidad',
      },
    ],
  },
  {
    id: 2,
    title: 'Nosotros',
    diffClass: '',
    list: [
      {
        id: 1,
        href: 'https://romikya.mx/contact',
        text: 'Contacto',
      },
      {
        id: 2,
        href: 'https://www.alamexicana.dev',
        text: 'A La Mexicana',
      },
    ],
  },
]

const SocialIcons = [
  {
    id: 1,
    href: 'https://github.com/romikya',
    text: 'github',
  },
  {
    id: 2,
    href: 'https://twitter.com/RomikyaLabs',
    text: 'twitter',
  },
  {
    id: 3,
    href: 'https://www.linkedin.com/company/romikya-labs/',
    text: 'linkedin',
  },
  {
    id: 4,
    href: '/rss',
    text: 'rss',
  },
]

export { LinkList, SocialIcons }
