const Config = {
  site: {
    name: 'A La Mexicana',
    URL: 'https://www.alamexicana.dev',
    title: 'Podcast - A La Mexicana',
    logo: '/images/logo.png',
    preview: '/preview.png',
    description: 'Entrevistas con personajes de la industria del software',
  },
  social: {
    twitter: 'https://twitter.com/@romikyalabs',
    github: 'https://github.com/romikya',
    linkedin: 'https://www.linkedin.com/company/romikya-labs',
  },
  google: {
    trackGA: '',
    trackGTM: '',
    trackAW: '',
  },
}

export default Config
