const site = 'https://www.alamexicana.dev'

export const IconSEO = {
  icons: {
    icon: [
      { url: '/favicon/favicon-16x16.png' },
      new URL('/favicon/favicon-16x16.png', site),
      {
        url: '/favicon/favicon-16x16.png',
        media: '(prefers-color-scheme: dark)',
      },
    ],
    shortcut: ['/favicon/favicon-16x16.png'],
    apple: [
      { url: '/favicon/apple-touch-icon.png' },
      {
        url: '/favicon/apple-touch-icon.png',
        sizes: '180x180',
        type: 'image/png',
      },
    ],
    android: [
      {
        url: '/favicon/android-chrome-192x192.png',
        sizes: '192x192',
        type: 'image/png',
      },
      {
        url: '/favicon/android-chrome-512x512.png',
        sizes: '512x512',
        type: 'image/png',
      },
    ],
    other: [
      {
        rel: 'apple-touch-icon-precomposed',
        url: '/favicon/apple-touch-icon.png',
      },
    ],
  },
}

export const Authors = [
  { name: 'Iver' },
  { name: 'Ivan', url: 'https://iver.mx' },
  { name: 'RomikyaLabs', url: 'https://romikya.mx' },
]

export const Creator = 'Ivan Jaimes'

export const Keywords = ['Podcast', 'A La Mexicana', 'Entrevistas']

export const Alternates = {
  canonical: site,
  languages: {
    'es-MX': `${site}/es-MX`,
  },
  types: {
    'application/rss+xml': `${site}/rss`,
  },
}

export const OpenGraph = {
  url: site,
  type: 'website',
  images: [
    {
      url: '/images/logo2.svg',
      width: 480,
      height: 480,
      alt: 'Imagen de A La Mexicana',
    },
  ]
}