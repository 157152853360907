const Menu = {
  main: [
    { id: '1', name: 'Inicio', link: '/' },
    { id: '2', name: 'Episodios', link: '/episodes' },
    { id: '3', name: 'Contacto', link: 'https://romikya.mx/contact' },
  ],
  terms: [
    { id: '1', name: 'Inicio', link: '/' },
    { id: '2', name: 'Contacto', link: 'https://romikya.mx/contact' },
  ],
  politics: [
    { id: '1', name: 'Inicio', link: '/' },
    { id: '2', name: 'Contacto', link: 'https://romikya.mx/contact' },
  ],
}

export default Menu
